import Badge from '@ingka/badge';
import { useNCAListFilter } from './hooks/useNCAListFilter';
import Button from '@ingka/button';
import { useTranslate } from 'hooks/useTranslate';
import { WrappingRow } from 'components/common/WrappingRow/WrappingRow';
import { styled } from 'styled-components';

export const BadgeValue = styled.span`
  font-style: italic;
`;

export function NCAListFilter() {
  const t = useTranslate();
  const { searchParams, handleFilterCleared, handleClearAllFilters } = useNCAListFilter();

  return (
    <WrappingRow>
      <Button
        onClick={handleClearAllFilters}
        text={t('clearAllFilters')}
        size="small"
        disabled={searchParams.length === 0}
      />
      {searchParams.map((param) => (
        <span key={'nca-filter-badge-' + param.key} onClick={() => handleFilterCleared(param.key)}>
          <Badge
            label={
              <span>
                {t(param.key)}: <BadgeValue>{param.value}</BadgeValue>
              </span>
            }
          />
        </span>
      ))}
    </WrappingRow>
  );
}
