import { GetOneNCAResponse, NCADto } from 'api/genTypes/dto';
import { DataTableColumn } from 'components/DataTable/DataTable.types';

export const ncaListColumns: DataTableColumn<NCADto>[] = [
  {
    columnId: 'nonConformityAdviceId',
    columnType: 'link',
  },
  {
    columnId: 'shipmentId',
    columnType: 'string',
  },
  {
    columnId: 'tspName',
    columnType: 'string',
  },
  {
    columnId: 'tspId',
    columnType: 'string',
  },
  {
    columnId: 'tag',
    columnType: 'string',
  },
  {
    columnId: 'amount',
    columnType: 'number',
  },
  {
    columnId: 'currency',
    columnType: 'string',
  },
  {
    columnId: 'costType',
    columnType: 'string',
  },
  {
    columnId: 'modeOfTransport',
    columnType: 'string',
  },
  {
    columnId: 'flow',
    columnType: 'string',
  },
  {
    columnId: 'actualStartTime',
    columnType: 'string',
  },
  {
    columnId: 'actualEndTime',
    columnType: 'string',
  },
  {
    columnId: 'created',
    columnType: 'date',
  },
  {
    columnId: 'lastUpdated',
    columnType: 'date',
  },
  {
    columnId: 'assignee',
    columnType: 'string',
  },
  {
    columnId: 'status',
    columnType: 'select',
    options: [],
  },
  {
    columnId: 'resolutionDate',
    columnType: 'date',
  },
  {
    columnId: 'startCountry',
    columnType: 'string',
  },
  {
    columnId: 'stopCountry',
    columnType: 'string',
  },
  {
    columnId: 'startAddressReference',
    columnType: 'string',
  },
  {
    columnId: 'stopAddressReference',
    columnType: 'string',
  },
  {
    columnId: 'consignorReference',
    columnType: 'string',
  },
  {
    columnId: 'consigneeReference',
    columnType: 'string',
  },
  {
    columnId: 'claimLocation',
    columnType: 'string',
  },
  {
    columnId: 'rateTimeStart',
    columnType: 'date',
  },
  {
    columnId: 'rateTimeEnd',
    columnType: 'date',
  },
  {
    columnId: 'updatedBy',
    columnType: 'string',
  },
  {
    columnId: 'chargeParty',
    columnType: 'string',
  },
  {
    columnId: 'serviceBuyerReference',
    columnType: 'string',
  },
  {
    columnId: 'freetimeStartDate',
    columnType: 'date',
  },
  {
    columnId: 'freetimeEndDate',
    columnType: 'date',
  },
  {
    columnId: 'resolvedBy',
    columnType: 'person',
  },
  {
    columnId: 'serviceSellerReference',
    columnType: 'string',
  },
  {
    columnId: 'resolutionNotes',
    columnType: 'string',
  },
  {
    columnId: 'causingParty',
    columnType: 'string',
  },
  {
    columnId: 'workNotes',
    columnType: 'string',
  },
  {
    columnId: 'dateResolved',
    columnType: 'date',
  },
  {
    columnId: 'datePaid',
    columnType: 'date',
  },
  {
    columnId: 'plannedStartTime',
    columnType: 'date',
  },
  {
    columnId: 'plannedEndTime',
    columnType: 'date',
  },
  {
    columnId: 'equipmentGroup',
    columnType: 'string',
  },
  {
    columnId: 'comment',
    columnType: 'string',
  },
];

export const ncaDetailsColumns: DataTableColumn<GetOneNCAResponse>[] = [...ncaListColumns];
