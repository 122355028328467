import { Link } from 'react-router-dom';
import { DataTableEntity } from '../../DataTable.types';
import { DataTableCellProps } from './DataTableCell.types';

export function DataTableCell<T extends DataTableEntity>({
  column,
  dataItem: entity,
}: DataTableCellProps<T>) {
  const renderValue = (value: any): string => {
    if (!value) {
      return undefined;
    }

    return String(value);
  };

  const value = entity[column.columnId];

  if (column.columnType === 'boolean') {
    return <input type="checkbox" checked={Boolean(value)} disabled />;
  }

  if (column.columnType === 'date' && value) {
    const dateFormatRegex: RegExp =
      /^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}):\d{2}\.\d{3}([+-]\d{2}:\d{2})$/;
    const dateFormatMatch = renderValue(value).match(dateFormatRegex);

    if (dateFormatMatch) {
      const [, datePart, timePart, timezonePart] = dateFormatMatch;

      return <>{`${datePart} ${timePart} ${timezonePart}`}</>;
    }
  }

  if (column.columnType === 'link') {
    return <Link to={entity[column.columnId]}>{renderValue(entity[column.columnId])}</Link>;
  }

  return <>{renderValue(entity[column.columnId])}</>;
}
