import Button from '@ingka/button';
import { Select, Option } from '@ingka/select';
import { StackSpaced } from 'components/layout';
import { useTranslate } from 'hooks/useTranslate';
import { useNCAControlsActionSelect } from '../../../../hooks/useNCAControlsActionSelect';
import { ClaimControlOptions } from '../../NCAControls.types';

export function NCAControlsActionSelect({
  onSubmit,
  isClaimApprovable,
  isClaimRejectable,
  isClaimSendableToLDM,
}: ClaimControlOptions) {
  const t = useTranslate();

  const { action, actions, handleChange, handleSubmit, canSubmit } = useNCAControlsActionSelect(
    isClaimApprovable,
    isClaimRejectable,
    isClaimSendableToLDM,
    onSubmit
  );

  return (
    <StackSpaced>
      <Select
        id="NCAControlsActionSelectId"
        data-testid="NCAControlsActionSelectId"
        label={t('selectAction')}
        multiple={false}
        value={action}
        onChange={(e) => handleChange(e.target.value)}
      >
        {actions.map((action) => (
          <Option
            id={action.key}
            key={'nca-controls-action-' + action.key}
            name={t(action.key)}
            value={action.key}
            disabled={action.disabled}
          />
        ))}
      </Select>
      <Button type="emphasised" onClick={handleSubmit} disabled={!canSubmit}>
        {t('saveAndSubmit')}
      </Button>
    </StackSpaced>
  );
}
