import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import Badge from '@ingka/badge';
import filters from '@ingka/ssr-icon/paths/filters';

import { DataTableEntity } from 'components/DataTable/DataTable.types';
import { DataTableColumnHeaderProps } from '../../DataTableColumnHeader.types';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import { useDisclosure } from 'utils/hooks';
import { useTranslate } from 'hooks/useTranslate';
import { styled } from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import InputField from '@ingka/input-field';
import { useEffect, useState } from 'react';
import { Stack } from 'components/layout';

const ColumnHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  white-space: nowrap;
  flex-wrap: nowrap;
`;

function getMinMaxColumnIds(columnId: string): [string, string] {
  const columnIdMin: string = columnId + 'Min';
  const columnIdMax: string = columnId + 'Max';

  return [columnIdMin, columnIdMax];
}

function useDataTableColumnHeaderFilterModalForm(
  columnId: string,
  localSearchParams: URLSearchParams,
  setLocalSearchParams: (value: URLSearchParams) => void
) {
  const handleOnChange = (key: string, value: string | number | Date | undefined | null) => {
    // If the key is empty or undefined, we do nothing.
    if (!key) {
      return;
    }

    // Prepare to update the search params
    const updatedSearchParams = new URLSearchParams(localSearchParams);

    // First, parse the value (it might be a string, boolean, date, etc.)
    // And it needs to be in a string format before we can save it to the URL.
    let parsedValue: string = '';

    if (typeof value === 'string') {
      parsedValue = value;
    } else if (typeof value === 'number') {
      parsedValue = value.toString();
    } else if (value instanceof Date) {
      parsedValue = value.toISOString();
    }

    // If neither of the above were true, the parsedValue remains the empty string it was initialzied as.

    // If the value is empty or undefined, we clear it entirely from the search params
    if (!parsedValue) {
      updatedSearchParams.delete(key);
    }
    // Otherwise, we update or set the value of that search param
    else {
      // Set the value in the updated search params
      updatedSearchParams.set(key, parsedValue);
    }

    // Update local search params state. Don't actually update the URL yet (wait until with submit with a click)
    setLocalSearchParams(updatedSearchParams);
  };

  // Create a min and max version of the columnId for use with number and date ranges
  const [columnIdMin, columnIdMax] = getMinMaxColumnIds(columnId);

  return {
    handleOnChange,
    columnIdMin,
    columnIdMax,
  };
}

/** The form for inputting filter data. */
export function DataTableColumnHeaderFilterModalForm<T extends DataTableEntity>({
  column: { columnId, columnType },
  localSearchParams,
  setLocalSearchParams,
}: DataTableColumnHeaderProps<T> & {
  localSearchParams: URLSearchParams;
  setLocalSearchParams: (value: URLSearchParams) => void;
}) {
  const t = useTranslate();

  // Get the local component logic (extracted to its own hook)
  const { handleOnChange, columnIdMin, columnIdMax } = useDataTableColumnHeaderFilterModalForm(
    columnId,
    localSearchParams,
    setLocalSearchParams
  );

  // If the columnType is a number, then we instead render a min-max number form
  if (columnType === 'number') {
    return (
      <>
        <InputField
          id={`${columnId}-min-filter`}
          type="number"
          label={t('min')}
          value={localSearchParams.get(columnIdMin) || ''}
          onChange={(e) => handleOnChange(columnIdMin, e.target.value)}
          data-autofocus
        />
        <InputField
          id={`${columnId}-max-filter`}
          type="number"
          label={t('max')}
          value={localSearchParams.get(columnIdMax) || ''}
          onChange={(e) => handleOnChange(columnIdMax, e.target.value)}
        />
      </>
    );
  }
  // If the columnType is a date, then we instead render a min-max date form
  // The IKEA Skapa component library does not seem to have a date input component, so we just use the default one.
  // See: https://react.skapa.ikea.net/?path=/docs/inputs-controls-input-field--documentation
  else if (columnType === 'date') {
    return (
      <Stack>
        <label htmlFor={`${columnId}-min-filter`}>{t('min')}</label>
        <input
          id={`${columnId}-min-filter`}
          type="date"
          value={localSearchParams.get(columnIdMin) || ''}
          onChange={(e) => handleOnChange(columnIdMin, e.target.value)}
          data-autofocus
        />
        <label htmlFor={`${columnId}-max-filter`}>{t('max')}</label>
        <input
          id={`${columnId}-max-filter`}
          type="date"
          value={localSearchParams.get(columnIdMax) || ''}
          onChange={(e) => handleOnChange(columnIdMax, e.target.value)}
        />
      </Stack>
    );
  }

  // As a fallback, we always render a text field filter.
  return (
    <InputField
      id={`${columnId}-filter`}
      type="text"
      label={t('textFilter')}
      value={localSearchParams.get(columnId) || ''}
      onChange={(e) => handleOnChange(columnId, e.target.value)}
      data-autofocus
    />
  );
}

function useDataTableColumnHeaderFilter(columnId: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [localSearchParams, setLocalSearchParams] = useState<URLSearchParams>(searchParams);

  // Ensure that the local state is updated whenver the URL state changes.
  useEffect(() => {
    setLocalSearchParams(searchParams);
  }, [searchParams, setLocalSearchParams]);

  // Handles disclosre, i.e. open/close, of the filter modal for this colum.
  const disclosure = useDisclosure();

  // The nbr of filters currently active on this column
  let nbrOfFilters: number = 0;

  if (searchParams.get(columnId)) nbrOfFilters++;
  if (searchParams.get(getMinMaxColumnIds(columnId)[0])) nbrOfFilters++;
  if (searchParams.get(getMinMaxColumnIds(columnId)[1])) nbrOfFilters++;

  // Update the actual URL search params state (in the URL address bar) with the updated params
  // We also close the filter modal
  const handleOnSubmit = () => {
    setSearchParams(localSearchParams);
    disclosure.close();
  };

  // Clear from the actual URL search params only the params that can possibly be set by this filter.
  const handleOnClear = () => {
    const clearedSearchParams: URLSearchParams = new URLSearchParams(searchParams);

    clearedSearchParams.delete(columnId);

    const [columnIdMin, columnIdMax] = getMinMaxColumnIds(columnId);

    clearedSearchParams.delete(columnIdMin);
    clearedSearchParams.delete(columnIdMax);

    setSearchParams(clearedSearchParams);
    setLocalSearchParams(clearedSearchParams);
  };

  return {
    disclosure,
    nbrOfFilters,
    localSearchParams,
    setLocalSearchParams,
    handleOnSubmit,
    handleOnClear,
  };
}

/** Represents the header of a DataTable column. Can be just a text label or include dynamic filtering options. */
export function DataTableColumnHeaderFilter<T extends DataTableEntity>({
  column,
}: DataTableColumnHeaderProps<T>) {
  const t = useTranslate();
  const {
    disclosure,
    nbrOfFilters,
    localSearchParams,
    setLocalSearchParams,
    handleOnSubmit,
    handleOnClear,
  } = useDataTableColumnHeaderFilter(column.columnId);

  return (
    <>
      {/* Modal that, when open, displays the filtering options and state for the column */}
      <Modal
        handleCloseBtn={disclosure.close}
        visible={disclosure.isOpen}
        // Ensure that the first input field of the modal is focused when opening it.
        // This increases usability by reducing the number of clicks needed to provide user input.
        // Also needs a `data-autofocus` property on the relevant input fields.
        focusLockProps={{ locked: true, autoFocus: true }}
        // Ensure that is is rendered above other modals and popups.
        style={{ zIndex: '100000' }}
        escapable
      >
        <Prompt
          aria-label="Filter"
          className="example-prompt-override"
          footer={
            <ModalFooter>
              <Button type="secondary" text={t('clearFilter')} onClick={handleOnClear} />
              <Button type="primary" text={t('applyFilter')} onClick={handleOnSubmit} />
            </ModalFooter>
          }
          header={<ModalHeader />}
          title={`${t('filter')}: ${t(column.columnId)}`}
          titleId={`${t('filter')}: ${t(column.columnId)}`}
        >
          <DataTableColumnHeaderFilterModalForm
            column={column}
            localSearchParams={localSearchParams}
            setLocalSearchParams={setLocalSearchParams}
          />
        </Prompt>
      </Modal>

      {/* A button that represents the column header. If clicked, it will open the filter modal. */}
      <Button size="xsmall" type="tertiary" onClick={disclosure.open}>
        <ColumnHeaderContent>
          {t(column.columnId)}
          <SSRIcon paths={filters} />
          {nbrOfFilters > 0 && <Badge colour="grey" label={nbrOfFilters} size="small" />}
        </ColumnHeaderContent>
      </Button>
    </>
  );
}
