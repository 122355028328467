import { GetOneNCAResponse } from 'api/genTypes/dto';
import { useNCADetailsBasicData } from './hooks/useNCADetailsBasicData';
import { DataTable } from 'components/DataTable/DataTable';

export interface NCADetailsBasicDataProps {
  nca?: GetOneNCAResponse;
}

export function NCADetailsBasicData({ nca }: NCADetailsBasicDataProps) {
  const { columns } = useNCADetailsBasicData();

  return <DataTable<GetOneNCAResponse> columns={columns} data={[nca]} />;
}
